import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import lazysizes from 'lazysizes'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/select-handler'

import 'js/components/tracking'

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

if (document.querySelector('html[lang="ar"]')) {
  import(/* webpackChunkName: "langLinkAlert" */ 'js/utils/lang-link-alert')
}

//===============================================================/
//  =components
//===============================================================/

/*if (document.querySelector('.cookie-consent')) {
  import(/!* webpackChunkName: "compCookieConsent" *!/ 'js/components/cookieConsent')
}*/

if (document.querySelector('.js-quote-tabs')) {
  import(/* webpackChunkName: "quoteTabs" */ 'js/components/quote-tabs')
}

if (document.querySelector('.js-quote-selection')) {
  import(/* webpackChunkName: "fixedForms" */ 'js/components/fixed-forms')
}

if (document.querySelector('.js-custom-form')) {
  import(/* webpackChunkName: "customForm" */ 'js/components/custom-form')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.b-packages-block')) {
  import(/* webpackChunkName: "blockNameHere" */ 'js/blocks/packages')
}

if (document.querySelector('.b-accordion-block')) {
  import(/* webpackChunkName: "blockNameHere" */ 'js/blocks/accordion')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.site-header-container')) {
  import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

if (document.querySelector('.js-quote-form')) {
  import(/* webpackChunkName: "quoteFormBupa" */ 'js/pages/quote-form')
}
